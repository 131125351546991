import { Container, Row, Col } from 'react-bootstrap';
import BrochureDownload from './BrochureDownload';
import BackgroundImage from '../../assets/images/IISWBM-Entrance.jpg';
import FeatureImage from '../../assets/images/IISWBM-gate.jpg';
import './index.scss';

const BrochureSection = (props) => {
    return (
        <section
            id="brochure-section"
            className="my-0 mt-md-5"
            style={{
                backgroundImage: `url(${BackgroundImage})`,
            }}
        >
            <Container fluid>
                <Row className="justify-content-md-start">
                    <Col xs={12} className="d-flex d-lg-none">
                        <Row>
                            <img
                                className="img-fluid px-0"
                                src={FeatureImage}
                                alt="Indian Institute of Social Welfare & Business Management"
                                loading="lazy"
                            />
                        </Row>
                    </Col>
                    <Col
                        xs={12}
                        lg={7}
                        xxl={6}
                        className="black-gradient-reverse"
                    >
                        <Row className="py-3 py-md-5 g-0 justify-content-center">
                            <Col
                                md={11}
                                lg={{ span: 11, offset: 1 }}
                                xl={{ span: 10, offset: 0 }}
                                xxl={{ span: 11, offset: 1 }}
                                className="py-0 py-md-3"
                            >
                                <BrochureDownload
                                    pdfBrochure={props.courseBrochure}
                                    pdfTitle={props.brochureFileName}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default BrochureSection;
