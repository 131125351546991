import axios from 'axios';
import { toast } from 'react-toastify';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import {
    courseUrl,
    FormErrorMessage,
    CmiCommonData,
    GetUrlParams,
} from '../../HelperFunctions';
import { FormDataProcessing } from '../../FormHandling';
import './index.scss';
const LeadForm = () => {
    const location = useLocation();
    const history = useHistory();
    const leadName = 'Lead Form';
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data, e) => {
        const formData = [
            {
                FirstName: data.leadFullName,
                Email: data.leadEmailId,
                MobileNumber: data.leadContactNo,
                Entity4: data.leadEducation,
                LeadName: leadName,
                ...GetUrlParams(location),
            },
        ];
        let allData = FormDataProcessing(
            location,
            courseUrl,
            CmiCommonData,
            formData
        );
        const apiData = JSON.stringify(allData);
        //console.log(apiData);
        axios
            .post(
                'https://thirdpartyapi.extraaedge.com/api/SaveRequest',
                apiData
            )
            .then(function (response) {
                if (response.data === 'Success' || response.status === 200) {
                    history.push('/thank-you');
                }
            })
            .catch(function (error) {
                console.log(error.toJSON());
                toast.error(
                    'We are sorry, something went wrong! Please try again later.',
                    {
                        position: 'top-right',
                        theme: 'dark',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    return (
        <Card className="border-0 rounded-0 p-2 shadow">
            <Card.Body className="p-4 p-md-4 bg-cream">
                <Card.Title className="fw-medium">
                    Are you ready to skyrocket your career?
                </Card.Title>
                <p className="small">
                    Fill out the form and we'll get back to you with all the
                    information.
                </p>
                <Form
                    className="h-100"
                    id="leadCaptureForm"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Row>
                        <Form.Group
                            as={Col}
                            xs="12"
                            sm="6"
                            lg="12"
                            className="mb-3"
                            controlId="leadFullName"
                        >
                            <Form.Label>
                                Name{' '}
                                <sup
                                    className="text-danger"
                                    aria-label="This field is required"
                                >
                                    *
                                </sup>
                            </Form.Label>
                            <Form.Control
                                name="leadFullName"
                                className={`rounded-0 ${
                                    errors.leadFullName ? 'border-danger' : ''
                                }`}
                                size="lg"
                                type="text"
                                required
                                placeholder="Write your full name"
                                {...register('leadFullName', {
                                    required: 'Your full name is required',
                                    pattern: {
                                        value: /^[a-zA-Z]+((['. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                                        message:
                                            "Only alphabets, space, ' and . allowed",
                                    },
                                })}
                            />
                            <FormErrorMessage errors={errors.leadFullName} />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            xs="12"
                            sm="6"
                            lg="12"
                            controlId="leadEmailId"
                        >
                            <Form.Label>
                                Email id{' '}
                                <sup
                                    className="text-danger"
                                    aria-label="This field is required"
                                >
                                    *
                                </sup>
                            </Form.Label>
                            <Form.Control
                                className={`rounded-0 ${
                                    errors.leadEmailId ? 'border-danger' : ''
                                }`}
                                name="leadEmailId"
                                size="lg"
                                type="email"
                                placeholder="Write your email id"
                                required
                                {...register('leadEmailId', {
                                    required: 'Please enter your email id',
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: 'Invalid email address',
                                    },
                                })}
                            />
                            <FormErrorMessage errors={errors.leadEmailId} />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            xs="12"
                            sm="6"
                            lg="12"
                            controlId="leadContactNo"
                        >
                            <Form.Label>
                                Contact no{' '}
                                <sup
                                    className="text-danger"
                                    aria-label="This field is required"
                                >
                                    *
                                </sup>
                            </Form.Label>
                            <Form.Control
                                className={`rounded-0 ${
                                    errors.leadContactNo ? 'border-danger' : ''
                                }`}
                                name="leadContactNo"
                                size="lg"
                                type="text"
                                placeholder="Write your mobile no"
                                required
                                {...register('leadContactNo', {
                                    required: 'Your mobile no is quired',
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message:
                                            'Only 10 digit mobile no is allowed',
                                    },
                                })}
                            />
                            <FormErrorMessage errors={errors.leadContactNo} />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            xs="12"
                            sm="6"
                            lg="12"
                            controlId="leadEducation"
                        >
                            <Form.Label>
                                Education qualification{' '}
                                <sup
                                    className="text-danger"
                                    aria-label="This field is required"
                                >
                                    *
                                </sup>
                            </Form.Label>
                            <Form.Select
                                size="lg"
                                className={`rounded-0 ${
                                    errors.leadEducation ? 'border-danger' : ''
                                }`}
                                name="leadEducation"
                                required
                                {...register('leadEducation', {
                                    required:
                                        'Present education qualification is required',
                                })}
                            >
                                <option value="">
                                    Select education qualification
                                </option>
                                <option value="Under Graduate (Final Year)">
                                    Under Graduate (Final Year)
                                </option>
                                <option value="Graduate">Graduate</option>
                                <option value="Post Graduate">
                                    Post Graduate
                                </option>
                            </Form.Select>
                            <FormErrorMessage errors={errors.leadEducation} />
                        </Form.Group>
                        <div className="d-grid mt-3 col-xs-12 col-sm-6 col-lg-12">
                            <Button
                                variant="danger"
                                size="lg"
                                className="rounded-0 text-uppercase"
                                type="submit"
                            >
                                Apply now
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default LeadForm;
