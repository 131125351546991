import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import App from './App';
import './index.css';

ReactDOM.render(
    <React.StrictMode>
        {/* <BrowserRouter basename="/promotion"> */}
        <BrowserRouter>
            <App />
            <ScrollToTop />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
