import { Container, Row, Col, Image } from 'react-bootstrap';
import './index.scss';

const CareerPlacement = (props) => {
    return (
        <section className="pt-5">
            <Container fluid="md">
                <Row>
                    <Col xs={12} md={{ span: 6, order: 1 }} className="mb-5">
                        <article className="masonry-brands">
                            <Image
                                fluid
                                src={props.featureImage}
                                loading="lazy"
                                width="650px"
                                height="auto"
                            />
                        </article>
                    </Col>
                    <Col
                        xs={12}
                        md={{ span: 6, order: 0 }}
                        className="mb-5 d-flex flex-column justify-content-center"
                    >
                        <h3>{props.title}</h3>
                        <p className="fs-5 text-secondary">{props.subTitle}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default CareerPlacement;
