import React from 'react';
import { Card } from 'react-bootstrap';
import Slider from 'react-slick';
import './index.scss';

const AlumniSlider = (props) => {
    const list = props.alumniList;
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1130,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                },
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                },
            },
        ],
    };
    const carouselItems = list.map((alumni) => (
        <Card
            className="border-0 rounded-0 h-100 shadow-sm"
            id="alumni"
            key={alumni.id}
        >
            <Card.Img
                variant="top"
                src={alumni.image}
                className="rounded-0"
                loading="lazy"
            />
            <Card.Body className="bg-cream d-flex flex-column">
                <p className="x-small text-danger lh-1 mb-1 text-uppercase fw-bold">
                    {alumni.batch}
                </p>
                <Card.Title className="h6">{alumni.name}</Card.Title>
                <Card.Text className="small lh-1 mb-1 mt-auto">
                    {alumni.designation}
                </Card.Text>
                <Card.Text className="fw-medium lh-sm small">
                    {alumni.company}
                </Card.Text>
            </Card.Body>
        </Card>
    ));
    return (
        <div>
            <Slider {...settings}>{carouselItems}</Slider>
        </div>
    );
};
export default React.memo(AlumniSlider);
