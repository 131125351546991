import { GetPageSlug, GetCourseId } from './HelperFunctions';

export const FormDataProcessing = (
    location,
    pageUrls,
    commonData,
    formData
) => {
    const slug = GetPageSlug(location);
    let course = GetCourseId(slug, pageUrls);
    const courseId = [{ Location: `${course}` }];
    const dataObjects = [...commonData, ...courseId, ...formData];
    let dataObject = dataObjects.reduce(function (
        commonData,
        courseId,
        formData
    ) {
        return Object.assign(commonData, courseId, formData);
    },
    {});
    return dataObject;
};
