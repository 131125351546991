import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import CmiLogoPng from '../../assets/images/CMI-Logo.png';
import PhoneIcon from '../../assets/icons/el_phone-alt.svg';
import './index.scss';

const Header = () => {
    return (
        <header id="header" className="d-flex align-items-center py-3">
            <Container>
                <Row className="justify-content-between">
                    <Col xs={4} sm={3} md={2} lg={2} xl={2}>
                        <a href="/" title="Go back to CMI home page">
                            <Image
                                src={CmiLogoPng}
                                fluid
                                alt="Calcutta Media Institute logo"
                                width={130}
                            />
                        </a>
                    </Col>
                    <Col
                        xs={6}
                        sm={7}
                        md={7}
                        lg={5}
                        xl={4}
                        className="d-flex align-items-center"
                    >
                        <Row className=" justify-content-end">
                            <Col
                                xs={6}
                                sm={5}
                                md={5}
                                lg={6}
                                xl={5}
                                className="d-none d-sm-flex align-items-center"
                            >
                                <p className="small text-secondary mb-2 mb-md-0 lh-sm">
                                    Not sure if this course is right for you?
                                </p>
                            </Col>
                            <Col xs={9} sm={5} md={5} lg={5} xl={5}>
                                <Button
                                    id="call-button"
                                    href="tel:+919051653877"
                                    variant="outline-danger"
                                    className="d-flex text-end text-sm-start justify-content-center align-items-center"
                                    data-click="lpHeaderSection"
                                >
                                    <span className="order-sm-1">
                                        {' '}
                                        Talk to a counsellor
                                    </span>
                                    <object
                                        type="image/svg+xml"
                                        data={PhoneIcon}
                                        className="icon icon-phone me-0 me-sm-2 ms-2 ms-sm-0 order-sm-0"
                                        aria-label="presentation"
                                    >
                                        Call
                                    </object>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default Header;
