import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';

import './index.scss';

const ThankYou = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Calcutta Media Institute</title>
                    <meta
                        name="description"
                        content="CMI (an ABP initiative), Best Media Institute for PGDM courses on Integrated Marketing Communication, Digital Marketing, Broadcast Management &amp; other short courses."
                    />
                    <meta
                        name="keywords"
                        content="PG Courses, Media management, ABP ltd., Calcutta University, Best Media school in Kolkata"
                    />
                </Helmet>
                <Header />

                <section className="bg-danger text-white py-5">
                    <Container>
                        <Row className="my-5">
                            <Col xs={12} md={9} lg={7}>
                                <h1 className="fs-4">
                                    <span className="text-cream fs-2">
                                        Thank You
                                    </span>
                                    , for getting in touch with us!
                                </h1>
                                <p className="fs-5">
                                    Our counsellors will get back to you
                                    shortly, and we hope to see you at our
                                    campus soon.
                                </p>
                                <p className="mt-5">
                                    In the mean time, if you are curious about
                                    Calcutta Media Institute (CMI) or want to
                                    know more about other Post Graduate and
                                    Professional courses we offer, please visit
                                    our website{' '}
                                    <a
                                        className="text-cream fw-bold"
                                        href="https://www.cmi.net.in"
                                        title="visit CMI website"
                                    >
                                        www.cmi.net.in
                                    </a>{' '}
                                    or call{' '}
                                    <a
                                        className="text-cream fw-bold"
                                        href="tel:+919051653877"
                                        title="Call CMI"
                                    >
                                        9051653877
                                    </a>{' '}
                                    .
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer />
            </HelmetProvider>
        </>
    );
};

export default ThankYou;
