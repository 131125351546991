export const imcAlumni = [
    {
        id: 1,
        image: 'https://cmi.net.in/promotion/alumni/imc/twameka.jpg',
        name: 'Twameka Kumar',
        batch: 'Batch 2012 - 13',
        designation: 'Group Head,',
        company: 'Wavemaker (Group M)',
    },
    {
        id: 2,
        name: 'Amrita Ray',
        image: 'https://cmi.net.in/promotion/alumni/imc/amrita.jpg',
        batch: 'Batch 2013-14',
        designation: 'Manager, Brand & Marketing,',
        company: 'Zee Entertainment Enterprises Ltd',
    },
    {
        id: 3,
        name: 'Sweta Rudra',
        image: 'https://cmi.net.in/promotion/alumni/imc/sweta.jpg',
        batch: 'Batch 2014-15',
        designation: 'Brand Manager,',
        company: 'Dot & Key',
    },
    {
        id: 4,
        name: 'Shuvranshu Mitra',
        image: 'https://cmi.net.in/promotion/alumni/imc/shuvranshu.jpg',
        batch: 'Batch 2015 - 16',
        designation: 'Senior Brand Executive,',
        company: 'ABP Group',
    },
    {
        id: 5,
        name: 'Abhilasha Kulshreshtha',
        image: 'https://cmi.net.in/promotion/alumni/imc/abhilasha.jpg',
        batch: 'Batch 2016 - 17',
        designation: 'Assistant Brand Manager,',
        company: 'Global Consumer Products Pvt. Ltd.',
    },
    {
        id: 5,
        name: 'Aditi Barua',
        image: 'https://cmi.net.in/promotion/alumni/imc/adhiti.jpg',
        batch: 'Batch 2018 - 19',
        designation: 'Analyst,',
        company: 'Deloitte India',
    },
];

export const bmAlumni = [
    {
        id: 1,
        name: 'Dipayan Dutta',
        image: 'https://cmi.net.in/promotion/alumni/bm/dipayan.jpg',
        batch: 'Batch 2012-13',
        designation: 'Executive Producer,',
        company: 'Jeetz Filmworks Pvt. Ltd',
    },
    {
        id: 2,
        image: 'https://cmi.net.in/promotion/alumni/bm/adrija.jpg',
        name: 'Adrija Nair',
        batch: 'Batch 2012 - 13',
        designation: 'Creative Copywriter,',
        company: 'Madison World',
    },
    {
        id: 3,
        name: 'Dipjyoti Banerjee',
        image: 'https://cmi.net.in/promotion/alumni/bm/dipjyoti.jpg',
        batch: 'Batch 2013-14',
        designation: 'Commercial / Procurement Manager,',
        company: 'Zee Entertainment Enterprises Limited',
    },
    {
        id: 4,
        name: 'Soutrik Sarkar',
        image: 'https://cmi.net.in/promotion/alumni/bm/soutrik.jpg',
        batch: 'Batch 2013 - 14',
        designation: 'Deputy Manager, Corporate Communication,',
        company: 'PCBL Limited',
    },
    {
        id: 5,
        name: 'Dyuti Sen',
        image: 'https://cmi.net.in/promotion/alumni/bm/dyuti.jpg',
        batch: 'Batch 2015 - 16',
        designation: 'Executive,',
        company: 'ABP Network',
    },
    {
        id: 5,
        name: 'Soumajit Roy',
        image: 'https://cmi.net.in/promotion/alumni/bm/soumajit.jpg',
        batch: 'Batch 2015 - 16',
        designation: 'Group Head,',
        company: 'Group M',
    },
    {
        id: 6,
        name: 'Shweta Mukherje',
        image: 'https://cmi.net.in/promotion/alumni/bm/shweta.jpg',
        batch: 'Batch 2016 - 17',
        designation: 'Assistant Manager Marketing,',
        company: 'Inox Leisure Limited',
    },
    {
        id: 7,
        name: 'Upasana Banerjee',
        image: 'https://cmi.net.in/promotion/alumni/bm/upasana.jpg',
        batch: 'Batch 2016 - 17',
        designation: 'Senior Copywriter,',
        company: 'Digital Refresh Networks',
    },
];

export const dmAlumni = [
    {
        id: 1,
        image: 'https://cmi.net.in/promotion/alumni/dmsm/tristuv.jpg',
        name: 'Tristuv Kumar',
        batch: 'Batch 2015 - 16',
        designation: 'Digital Marketing Manager,',
        company: 'M&C Saatchi',
    },
    {
        id: 2,
        name: 'Krishna Pal',
        image: 'https://cmi.net.in/promotion/alumni/dmsm/krishna.jpg',
        batch: 'Batch 2017 - 18',
        designation: 'Paid Media Specialist,',
        company: 'SocialPanga',
    },
    {
        id: 3,
        name: 'Sanjay Rajak',
        image: 'https://cmi.net.in/promotion/alumni/dmsm/sanjay.jpg',
        batch: 'Batch 2017 - 18',
        designation: 'Social Media Manager,',
        company: 'Nihilent Group',
    },
    {
        id: 4,
        name: 'Sayak Banerjee',
        image: 'https://cmi.net.in/promotion/alumni/dmsm/sayak.jpg',
        batch: 'Batch 2017 - 18',
        designation: 'Senior Analyst,',
        company: 'IPAC',
    },
    {
        id: 5,
        name: 'Shristi Basak',
        image: 'https://cmi.net.in/promotion/alumni/dmsm/shristi.jpg',
        batch: 'Batch 2018 - 19',
        designation: 'Digital Marketing Executive,',
        company: 'Resil Chemicals',
    },
    {
        id: 5,
        name: 'Krittika De',
        image: 'https://cmi.net.in/promotion/alumni/dmsm/krittika.jpg',
        batch: 'Batch 2018 - 19',
        designation: 'Communications Associate,',
        company: 'Sabyasachi Calcutta',
    },
    {
        id: 6,
        name: 'Arjun Trivedi',
        image: 'https://cmi.net.in/promotion/alumni/dmsm/arjun.jpg',
        batch: 'Batch 2019 - 20',
        designation: 'Brand Consultant,',
        company: 'Bridgegap Consultant',
    },
];
