import { Container, Row, Col, Image } from 'react-bootstrap';
import { Year } from '../HelperFunctions';
import CmiLogoPng from '../../assets/images/CMI-Logo.png';
import IISWBMLogoPng from '../../assets/images/iiswbm-logo-01.png';
import CULogoPng from '../../assets/images/University-of-Calcutta.png';
import BackToTop from '../../assets/icons/arrow-up-square.svg';
import './index.scss';

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <footer id="footer" className="mt-auto pt-5">
            <Container fluid="md">
                <Row className="justify-content-sm-center">
                    <Col sm={6} md={4} lg={4} className="pb-0 pb-md-5">
                        <Row>
                            <Col xs={6} md={6} lg={5}>
                                <a href="/" title="Go back to CMI home page">
                                    <Image
                                        src={CmiLogoPng}
                                        fluid
                                        alt="Calcutta Media Institute logo"
                                        width={155}
                                    />
                                </a>
                            </Col>
                            <Col xs={3} md={3} lg={3} className="text-center">
                                <Image
                                    src={IISWBMLogoPng}
                                    fluid
                                    alt="Indian institute of Social welfare and Business Development logo"
                                    width={70}
                                />
                            </Col>
                            <Col xs={3} md={3} lg={3} className="text-center">
                                <Image
                                    src={CULogoPng}
                                    fluid
                                    alt="University of Calcutta logo"
                                    width={70}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={11} md={12} lg={11}>
                                <address className="small mt-3">
                                    501, Elgin Chambers, 5th Floor, 1A, Ashutosh
                                    Mukherjee Road. Kolkata – 700020, West
                                    Bengal. India.
                                </address>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={8} lg={8} className="pb-0 pb-md-4">
                        <p className="small">
                            Calcutta Media Institute (CMI) is a wholly owned
                            subsidiary of ABP Pvt. Ltd., the largest media group
                            in Eastern India. Ever since its inception in 2010,
                            CMI has been in the business of creating
                            industry-ready professionals for the media,
                            communication, and entertainment industry. CMI has
                            created and delivered Post Graduate Diploma courses
                            in Integrated Marketing Communication Management,
                            Broadcast Management and Digital Marketing & Social
                            Media in partnership with IISWBM, leading to a Post
                            Graduate Diploma in Media Management under
                            University of Calcutta.
                        </p>
                        <Row className="g-md-0">
                            <Col xs={10} lg={11} className="border-top">
                                <p className="d-block small py-2 py-md-3 mb-0">
                                    &copy; <Year />. Calcutta Media Institute
                                    Pvt. Ltd., All rights reserved.
                                </p>
                            </Col>
                            <Col
                                xs={2}
                                lg={1}
                                className="border-top border-start d-flex justify-content-center align-items-center"
                            >
                                <Image
                                    id="up-button"
                                    src={BackToTop}
                                    fluid
                                    alt="Up arrow"
                                    width={32}
                                    className="shadow rounded-1"
                                    onClick={scrollToTop}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
