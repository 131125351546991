import { Container, Row, Col, Card } from 'react-bootstrap';
import { Year } from '../HelperFunctions';
import VerifiedIcon from '../../assets/icons/verified-badge.svg';
import './index.scss';
import LeadForm from './LeadForm';

const CourseIntro = (props) => {
    return (
        <section id="course-intro" className="mt-n5 mb-0 mb-lg-5">
            <Container fluid="md">
                <Row>
                    <Col xs={12} lg={8} className="mb-5 mb-lg-0">
                        <Card id="intro-text" className="border-0 rounded-0">
                            <Card.Body className="p-4 p-md-5 bg-white shadow">
                                <h2>{props.title}</h2>
                                <p className="fs-5 text-secondary">
                                    {props.subTitle}
                                </p>
                                <h5 className="mt-5 mb-4 mb-sm-5">
                                    Course overview
                                </h5>
                                <Row>
                                    <Col sm={6} lg={4}>
                                        <div className="d-flex mb-4">
                                            <div className="flex-shrink-0">
                                                <img
                                                    src={VerifiedIcon}
                                                    alt="Verified icon"
                                                />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                New session starting from August{' '}
                                                <Year />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} lg={4}>
                                        <div className="d-flex mb-4">
                                            <div className="flex-shrink-0">
                                                <img
                                                    src={VerifiedIcon}
                                                    alt="Verified icon"
                                                />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                Full-time offline
                                                post-graduation course
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} lg={4}>
                                        <div className="d-flex mb-4">
                                            <div className="flex-shrink-0">
                                                <img
                                                    src={VerifiedIcon}
                                                    alt="Verified icon"
                                                />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                PG Diploma awarded by University
                                                of Calcutta
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} lg={4}>
                                        <div className="d-flex mb-4">
                                            <div className="flex-shrink-0">
                                                <img
                                                    src={VerifiedIcon}
                                                    alt="Verified icon"
                                                />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                Learn from India's top marketing
                                                faculty & industry leaders
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} lg={4}>
                                        <div className="d-flex mb-4">
                                            <div className="flex-shrink-0">
                                                <img
                                                    src={VerifiedIcon}
                                                    alt="Verified icon"
                                                />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                Guaranteed internship and
                                                placement assistance
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} lg={4}>
                                        <div className="d-flex mb-4">
                                            <div className="flex-shrink-0">
                                                <img
                                                    src={VerifiedIcon}
                                                    alt="Verified icon"
                                                />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                {props.placementInfo}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={4} className="mb-5  mb-lg-0">
                        <LeadForm />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default CourseIntro;
