import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
import CourseIntro from '../../components/CourseIntro';
import AdvantageCmi from '../../components/AdvantageCmi';
import CourseCurriculum from '../../components/CourseCurriculum';
import CareerPlacement from '../../components/CareerPlacement';
import BrochureSection from '../../components/BrochureSection';
import AdmissionAlumniSection from '../../components/AdmissionAlumni';
import Footer from '../../components/Footer';
import ImcBanner from '../../assets/images/cmi-corridor-01.jpg';
import ImcBrandLogos from '../../assets/images/IMC-Brands-Desktop.png';
import { imcAlumni } from '../../assets/data';
import imcBrochure from '../../assets/brochures/pgdmm-imcm-brochure-2022.pdf';

import './index.scss';

const ImcManagement = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Integrated Marketing Course, The Best Post Graduation
                        Course in Kolkata
                    </title>
                    <meta
                        name="description"
                        content="Integrated Marketing Course, The Best Post Graduation Course in Kolkata from Calcutta Media Institute. Get placed in top companies. 100% placement assistance."
                    />
                    <meta
                        name="keywords"
                        content="media marketing classes, admission in pg courses, postgraduate studies, courses after graduation, media courses, best post graduation courses, courses to do after graduation,media studies college, integrated marketing course"
                    />
                </Helmet>
                <Header />
                <Banner
                    title="Become a Marketing Professional in just 1 year"
                    subTitle="Post Graduate Diploma in Media Management"
                    image={ImcBanner}
                />
                <CourseIntro
                    title="Specialise in Integrated Marketing Communication Management"
                    subTitle="A one-of-a-kind course created to give you the opportunity to gain industry exposure and acquire professional skills in order to pursue a successful career in India's marketing and marketing communication industries."
                    placementInfo="95%+ Campus placement record in last 5 years"
                />
                <AdvantageCmi
                    title={
                        <>
                            CMI <span className="text-danger">+</span>{' '}
                            IISWBM&nbsp;
                            <br />
                            double the advantage
                        </>
                    }
                    subTitle={
                        <>
                            Calcutta Media Institute (CMI), promoted by ABP Pvt.
                            Ltd. and the{' '}
                            <strong>
                                Indian Institute of Social Welfare &amp;
                                Business Management
                            </strong>{' '}
                            (IISWBM), a pioneer in business management studies
                            in India, have partnered to offer a one-year{' '}
                            <strong>
                                Post Graduate Diploma in Media Management
                            </strong>{' '}
                            (PGDMM) from <strong>University of Calcutta</strong>{' '}
                            with a specialisation in{' '}
                            <strong>
                                Integrated Marketing Communication Management
                            </strong>{' '}
                            (IMCM).
                        </>
                    }
                />
                <CourseCurriculum
                    title="Skills you will gain"
                    subtitle={
                        <>
                            <p>
                                One full academic year spread over two semesters
                                includes theory, projects, assignments, and
                                industry internships.
                            </p>
                        </>
                    }
                    columnOne={
                        <>
                            <li>Marketing Management</li>
                            <li>Brand Management</li>
                            <li>Advertising Development</li>
                            <li>New Social and Mobile Media</li>
                            <li>Public Relations</li>
                            <li>Culture and Communication</li>
                            <li>Sales Management</li>
                            <li>Communication Sales</li>
                            <li>Media Management</li>
                            <li>Media and Communications Research</li>
                        </>
                    }
                    columnTwo={
                        <>
                            <li>Creative Development</li>
                            <li>Brand/Account Planning</li>
                            <li>Media Planning and Buying</li>
                            <li>Rural Markets and Media</li>
                            <li>Events Management</li>
                            <li>
                                Business Economics and Accounting for Managers
                            </li>
                            <li>
                                Business Organization and Management Science in
                                Communications
                            </li>
                            <li>Internship and Project Submission</li>
                        </>
                    }
                />
                <CareerPlacement
                    title={
                        <>
                            Launch your career as a{' '}
                            <br className="d-none d-md-block" />
                            proficient Marketing professional
                        </>
                    }
                    subTitle="Choose from a wide range of career options like Brand Management, Public Relation, Event Management, Rural Marketing, Media Planning, Corporate Communication and more. Get to work with the best names in the industry!"
                    featureImage={ImcBrandLogos}
                />
                <BrochureSection
                    courseBrochure={imcBrochure}
                    brochureFileName="CMI-PGDMM-IMCM-Brochure-2022"
                />
                <AdmissionAlumniSection courseAlumni={imcAlumni} />
                <Footer />
            </HelmetProvider>
        </>
    );
};

export default ImcManagement;
