import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
import CourseIntro from '../../components/CourseIntro';
import AdvantageCmi from '../../components/AdvantageCmi';
import CourseCurriculum from '../../components/CourseCurriculum';
import CareerPlacement from '../../components/CareerPlacement';
import BrochureSection from '../../components/BrochureSection';
import AdmissionAlumniSection from '../../components/AdmissionAlumni';
import Footer from '../../components/Footer';

import DnSmmBrandLogos from '../../assets/images/DM-Brands-Desktop.png';
import { dmAlumni } from '../../assets/data';
import dmSmmBrochure from '../../assets/brochures/pgdmm-dmsmm-brochure-2022.pdf';

import DmSmmBanner from '../../assets/images/cmi-corridor-01.jpg';

import './index.scss';

const DmsManagement = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>
                        PG in Digital Marketing Course in Kolkata from CMI
                    </title>
                    <meta
                        name="description"
                        content="The best PG in Digital Marketing Course in Kolkata from Calcutta Media Institute. Get placed in top companies. 100% placement assistance."
                    />
                    <meta
                        name="keywords"
                        content="msc digital marketing, digital marketing programs, course after graduation, digital marketing degrees,digital marketing masters may 2022, digital course, post graduation course, digital marketing training, digital marketing course, marketing digital masters, courses in digital marketing, pg in digital marketing"
                    />
                </Helmet>
                <Header />
                <Banner
                    title="Post Graduation Course in Digital Marketing"
                    subTitle={
                        <>
                            Offered in Partnership with IISWBM <br />
                            Diploma Awarded by University of Calcutta
                        </>
                    }
                    image={DmSmmBanner}
                />
                <CourseIntro
                    title="Specialise in Digital Marketing &amp; Social Media Management"
                    subTitle="A full-time postgraduate digital marketing course created to give you the opportunity to gain industry exposure and acquire professional skills in Social Media, Content Marketing, Search Ads, Marketing Analytics and Online PR to pursue a successful career in digital marketing."
                    placementInfo="90%+ Campus placement record in last 5 years"
                />
                <AdvantageCmi
                    title={
                        <>
                            CMI <span className="text-danger">+</span>{' '}
                            IISWBM&nbsp;
                            <br />
                            double the advantage
                        </>
                    }
                    subTitle={
                        <>
                            Calcutta Media Institute (CMI), promoted by ABP Pvt.
                            Ltd. and the{' '}
                            <strong>
                                Indian Institute of Social Welfare &amp;
                                Business Management
                            </strong>{' '}
                            (IISWBM), a pioneer in business management studies
                            in India, have partnered to offer a one-year{' '}
                            <strong>
                                Post Graduate Diploma in Media Management
                            </strong>{' '}
                            (PGDMM) from <strong>University of Calcutta</strong>{' '}
                            with a specialisation in{' '}
                            <strong>
                                Digital Marketing & Social Media Management
                            </strong>{' '}
                            (DMSMM).
                        </>
                    }
                />
                <CourseCurriculum
                    title="Skills you will gain"
                    subtitle={
                        <>
                            <p>
                                PG in Digital Marketing course is spread across
                                1 full academic year, comprising 2 semesters
                                which includes theory classes, projects,
                                assignments, and industry internships.
                            </p>
                            <p className="fw-bold">
                                Get trained on the latest digital marketing
                                tools like Google Analytics, Google Ads,
                                Facebook ads and Mailchimp.
                            </p>
                        </>
                    }
                    columnOne={
                        <>
                            <li>
                                Business Organization and Management Science in
                                Communications
                            </li>
                            <li>
                                Business Economics and Basic Accounting for
                                Managers
                            </li>
                            <li>Marketing Management</li>
                            <li>Brand Management</li>
                            <li>
                                Media and Communication Research Methodologies
                            </li>
                            <li>Culture and Communication</li>
                            <li>Advertising and Creative Development</li>
                            <li>Events Management</li>
                        </>
                    }
                    columnTwo={
                        <>
                            <li>
                                Fundamentals of Digital Marketing and Website
                                design
                            </li>
                            <li>Social Media Marketing</li>
                            <li>Search Marketing</li>
                            <li>Online Display & Video Marketing</li>
                            <li>Digital Media Analytics</li>
                            <li>Online PR and Reputation Management</li>
                            <li>
                                Brand / Account Planning for Digital Marketing
                            </li>
                            <li>Integrated Media Planning and Buying</li>
                            <li>Mobile App and Media Marketing</li>
                            <li>Internship and Project Submission</li>
                        </>
                    }
                />
                <CareerPlacement
                    title={
                        <>
                            Launch your career as a Digital Marketing
                            Professional
                        </>
                    }
                    subTitle="Choose from a wide range of career options like Digital Strategist, Search Engine Optimisation and Search Engine Marketing Specialist, Content Strategist, Email Marketing Specialist, Marketing Analyst, Social media specialist and more. Get to work with the best names in the industry!"
                    featureImage={DnSmmBrandLogos}
                />
                <BrochureSection
                    courseBrochure={dmSmmBrochure}
                    brochureFileName="CMI-PGDMM-DMSMM-Brochure-2022"
                />
                <AdmissionAlumniSection courseAlumni={dmAlumni} />
                <Footer />
            </HelmetProvider>
        </>
    );
};

export default DmsManagement;
