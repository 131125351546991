import { Container, Row, Col } from 'react-bootstrap';

import AlumniCarousel from '../AlumniCarousel';
import './index.scss';

const AdmissionAlumniSection = (props) => {
    return (
        <section>
            <Container fluid>
                <Row>
                    <Col xs={12} md={5} className="bg-danger py-5">
                        <Row className="justify-content-sm-center justify-content-md-end">
                            <Col
                                xs={12}
                                sm={8}
                                md={11}
                                className="text-white px-3 ps-md-4 pe-md-2 ps-lg-3 pe-lg-4"
                            >
                                <h4 className="mb-5">
                                    CMI's selection process
                                </h4>
                                <article className="d-flex flex-column justify-space-around">
                                    <ul className="counter-list mt-0 mt-md-4">
                                        <li className="lead fw-medium mb-5">
                                            Apply Online at cmi.net.in or Call
                                            at{' '}
                                            <a
                                                href="tel: +919051653877"
                                                data-click="lpAdmissionSectionOne"
                                            >
                                                9051653877
                                            </a>{' '}
                                            /{' '}
                                            <a
                                                href="tel: +919831230988"
                                                data-click="lpAdmissionSectionTwo"
                                            >
                                                9831230988
                                            </a>
                                        </li>
                                        <li className="lead fw-medium mb-5">
                                            Group Discussions and Personal
                                            Interviews
                                        </li>
                                        <li className="lead fw-medium mb-5">
                                            Get shortlisted
                                        </li>
                                    </ul>
                                </article>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={7} className="bg-white py-5">
                        <Row className="justify-content-start">
                            <Col
                                xs={12}
                                md={11}
                                className="px-3 ps-md-5 pe-md-4 pe-lg-3"
                            >
                                <h4>
                                    Alumni in the{' '}
                                    <span className="text-danger">
                                        Spotlight
                                    </span>
                                </h4>

                                <AlumniCarousel
                                    alumniList={props.courseAlumni}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default AdmissionAlumniSection;
