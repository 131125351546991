import axios from 'axios';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { Row, Col, Form, Button, Card, Image } from 'react-bootstrap';

import {
    SessionDuration,
    courseUrl,
    FormErrorMessage,
    CmiCommonData,
    GetUrlParams,
} from '../../HelperFunctions';
import { FormDataProcessing } from '../../FormHandling';

import GoogleReview from '../../../assets/images/google-review.png';
import ShikshaReview from '../../../assets/images/shiksha-review.png';
import './index.scss';
const BrochureDownload = (props) => {
    const location = useLocation();
    const history = useHistory();
    const leadName = 'Download Form';
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data, e) => {
        const formData = [
            {
                FirstName: data.downloadFullName,
                Email: data.downloadEmailId,
                MobileNumber: data.downloadContactNo,
                Entity4: data.downloadEducation,
                LeadName: leadName,
                ...GetUrlParams(location),
            },
        ];
        let allData = FormDataProcessing(
            location,
            courseUrl,
            CmiCommonData,
            formData
        );
        const apiData = JSON.stringify(allData);
        //console.log(apiData);
        axios
            .post(
                'https://thirdpartyapi.extraaedge.com/api/SaveRequest',
                apiData
            )
            .then(function (response) {
                if (response.data === 'Success' || response.status === 200) {
                    history.push('/thank-you');
                }
                saveAs(props.pdfBrochure, props.pdfTitle);
            })
            .catch(function (error) {
                console.log(error.toJSON());
                toast.error(
                    'We are sorry, something went wrong! Please try again later.',
                    {
                        position: 'top-right',
                        theme: 'dark',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    return (
        <Card id="download-form" className="border-0 rounded-0 p-2 shadow">
            <Card.Body className="p-4 p-md-4 bg-cream">
                <Row className="justify-content-between">
                    <Col
                        xs={12}
                        sm={{ order: 1, span: 6 }}
                        md={{ order: 1, span: 5 }}
                        lg={{ order: 1, span: 5 }}
                    >
                        <Row className="g-3 g-sm-2">
                            <Col>
                                <Image
                                    fluid
                                    src={ShikshaReview}
                                    width="100px"
                                    height="auto"
                                    className="d-inline"
                                    alt="4.6 Star in Shiksha review"
                                />
                            </Col>
                            <Col>
                                <Image
                                    fluid
                                    src={GoogleReview}
                                    width="100px"
                                    height="auto"
                                    className="d-inline"
                                    alt="4 Star in Google review"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        xs={12}
                        sm={{ order: 0, span: 6 }}
                        md={{ order: 0, span: 7 }}
                        lg={{ order: 0, span: 6 }}
                    >
                        <Card.Title className="fw-medium mt-3 mt-sm-0">
                            Take the first step to become industry ready
                        </Card.Title>
                    </Col>
                </Row>
                <p className="small">
                    Fill up the form and download the <SessionDuration /> course
                    brochure now!
                </p>
                <hr className="d-none d-sm-block" />

                <Form
                    className="h-100"
                    id="downloadForm"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Row>
                        <Form.Group
                            as={Col}
                            xs="12"
                            sm="6"
                            className="mb-3"
                            controlId="downloadFullName"
                        >
                            <Form.Label>
                                Name <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Control
                                className={`rounded-0 ${
                                    errors.downloadFullName
                                        ? 'border-danger'
                                        : ''
                                }`}
                                name="downloadFullName"
                                size="lg"
                                type="text"
                                required
                                placeholder="Write your full name"
                                {...register('downloadFullName', {
                                    required: 'Your full name is required',
                                    pattern: {
                                        value: /^[a-zA-Z]+((['. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                                        message:
                                            "Only alphabets, space, ' and . allowed",
                                    },
                                })}
                            />
                            <FormErrorMessage
                                errors={errors.downloadFullName}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            xs="12"
                            sm="6"
                            controlId="downloadEmailId"
                        >
                            <Form.Label>
                                Email id <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Control
                                className={`rounded-0 ${
                                    errors.downloadEmailId
                                        ? 'border-danger'
                                        : ''
                                }`}
                                name="downloadEmailId"
                                size="lg"
                                type="email"
                                placeholder="Write your email id"
                                required
                                {...register('downloadEmailId', {
                                    required: 'Please enter your email id',
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: 'Invalid email address',
                                    },
                                })}
                            />
                            <FormErrorMessage errors={errors.downloadEmailId} />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            xs="12"
                            sm="6"
                            controlId="downloadContactNo"
                        >
                            <Form.Label>
                                Contact no <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Control
                                className={`rounded-0 ${
                                    errors.downloadContactNo
                                        ? 'border-danger'
                                        : ''
                                }`}
                                name="downloadContactNo"
                                size="lg"
                                type="text"
                                placeholder="Write your mobile no"
                                required
                                {...register('downloadContactNo', {
                                    required: 'Your mobile no is quired',
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message:
                                            'Only 10 digit mobile no is allowed',
                                    },
                                })}
                            />
                            <FormErrorMessage
                                errors={errors.downloadContactNo}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            xs="12"
                            sm="6"
                            controlId="formEducation"
                        >
                            <Form.Label>
                                Education qualification{' '}
                                <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Select
                                size="lg"
                                className={`rounded-0 ${
                                    errors.downloadEducation
                                        ? 'border-danger'
                                        : ''
                                }`}
                                name="downloadEducation"
                                required
                                {...register('downloadEducation', {
                                    required:
                                        'Present education qualification is required',
                                })}
                            >
                                <option value="">
                                    Select education qualification
                                </option>
                                <option value="Under Graduate (Final Year)">
                                    Under Graduate (Final Year)
                                </option>
                                <option value="Graduate">Graduate</option>
                                <option value="Post Graduate">
                                    Post Graduate
                                </option>
                            </Form.Select>
                            <FormErrorMessage
                                errors={errors.downloadEducation}
                            />
                        </Form.Group>
                        <div className="d-grid mt-auto col-xs-12 col-sm-6">
                            <Button
                                variant="danger"
                                size="lg"
                                className="rounded-0 text-uppercase mt-3"
                                type="submit"
                            >
                                Download now
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default BrochureDownload;
