import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './index.scss';
const Home = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Calcutta Media Institute - CMI</title>
                    <meta
                        name="description"
                        content="CMI (an ABP initiative), Best Media Institute in Kolkata for PGDM courses on Integrated Marketing Communication, Digital Marketing &amp; Social media marketing, Broadcast Management and other professional and skill enhancement courses."
                    />
                    <meta
                        name="keywords"
                        content="PG Courses, Media management, ABP ltd., Calcutta University, Best Media school in Kolkata"
                    />
                </Helmet>
                <Header />
                <section className="my-5">
                    <Container>
                        <Row>
                            <Col>
                                <nav>
                                    <ul>
                                        <li>
                                            <Link to="/integrated-marketing-communication-management">
                                                IMCM Landing page link
                                            </Link>{' '}
                                            ✅
                                        </li>
                                        <li>
                                            <Link to="/broadcast-management">
                                                BM Landing page link
                                            </Link>{' '}
                                            ✅
                                        </li>
                                        <li>
                                            <Link to="/digital-marketing-and-social-media-management">
                                                DMSMM Landing page link
                                            </Link>{' '}
                                            ✅
                                        </li>
                                        <li>
                                            <Link to="/thank-you">
                                                Thank you page link
                                            </Link>{' '}
                                            ✅
                                        </li>
                                        <li>
                                            <Link to="/404">
                                                Page not found page link
                                            </Link>{' '}
                                            ✅
                                        </li>
                                    </ul>
                                </nav>
                            </Col>
                            <Col>
                                <span className="fw-bold text-danger fs-5 lh-0">
                                    *
                                </span>{' '}
                                Please note, this is a temporary page, once the
                                app is deployed to production it will be
                                redirected to CMI home page.
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer />
            </HelmetProvider>
        </>
    );
};

export default Home;
