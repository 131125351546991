import { Row, Col, Card, Container, Image } from 'react-bootstrap';
// import UserSpeaker from '../../assets/icons/user-speaker.svg';
import GatewayUser from '../../assets/icons/gateway-user-access.svg';
import UserMultiple from '../../assets/icons/user-multiple.svg';
import UserAccess from '../../assets/icons/user-access.svg';
import BookShelf from '../../assets/icons/book-shelf.svg';
import CommunicationUnified from '../../assets/icons/communication-unified.svg';
import UserMilitary from '../../assets/icons/user-military.svg';
import PiggyBank from '../../assets/icons/piggy-bank.svg';
import MoneyHand from '../../assets/icons/money-hand.svg';
import './index.scss';
const AdvantageCmi = (props) => {
    return (
        <section className="my-0 my-lg-5" id="advantage-cmi">
            <Container fluid="md" className="pb-5 pb-md-0">
                <Row>
                    <Col sm={12} md={12} lg={8}>
                        <h2 className="mt-0 mb-4">{props.title}</h2>
                        <p>{props.subTitle}</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} md={6} lg={3} className="mt-4">
                        <Card className="border-0 rounded-0 shadow">
                            <Card.Body className="p-4">
                                <article className="d-flex">
                                    <div className="flex-shrink-0">
                                        <Image
                                            src={GatewayUser}
                                            alt="Icon"
                                            role="presentation"
                                            width="42px"
                                            height="42px"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-3 h6 fw-normal mb-0">
                                        Networking opportunity with alumni,
                                        industry experts
                                    </div>
                                </article>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={6} lg={3} className="mt-4">
                        <Card className="border-0 rounded-0 shadow">
                            <Card.Body className="p-4">
                                <article className="d-flex">
                                    <div className="flex-shrink-0">
                                        <Image
                                            src={UserMultiple}
                                            alt="Icon"
                                            role="presentation"
                                            width="42px"
                                            height="42px"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-3 h6 fw-normal mb-0">
                                        One-on-one sessions with mentors on
                                        project submissions
                                    </div>
                                </article>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={6} lg={3} className="mt-4">
                        <Card className="border-0 rounded-0 shadow">
                            <Card.Body className="p-4">
                                <article className="d-flex">
                                    <div className="flex-shrink-0">
                                        <Image
                                            src={UserAccess}
                                            alt="Icon"
                                            role="presentation"
                                            width="42px"
                                            height="42px"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-3 h6 fw-normal mb-0">
                                        Personalised guidance for Interview
                                        preparation
                                    </div>
                                </article>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={6} lg={3} className="mt-4">
                        <Card className="border-0 rounded-0 shadow">
                            <Card.Body className="p-4">
                                <article className="d-flex">
                                    <div className="flex-shrink-0">
                                        <Image
                                            src={BookShelf}
                                            alt="Icon"
                                            role="presentation"
                                            width="42px"
                                            height="42px"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-3 h6 fw-normal mb-0">
                                        Access to IISWBM library & cutting-edge
                                        learning resources
                                    </div>
                                </article>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={6} lg={3} className="mt-4">
                        <Card className="border-0 rounded-0 shadow">
                            <Card.Body className="p-4">
                                <article className="d-flex">
                                    <div className="flex-shrink-0">
                                        <Image
                                            src={CommunicationUnified}
                                            alt="Icon"
                                            role="presentation"
                                            width="42px"
                                            height="42px"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-3 h6 fw-normal mb-0">
                                        State-of-the-art audio-visual classrooms
                                    </div>
                                </article>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={6} lg={3} className="mt-4">
                        <Card className="border-0 rounded-0 shadow">
                            <Card.Body className="p-4">
                                <article className="d-flex">
                                    <div className="flex-shrink-0">
                                        <Image
                                            src={UserMilitary}
                                            alt="Icon"
                                            role="presentation"
                                            width="42px"
                                            height="42px"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-3 h6 fw-normal mb-0">
                                        CMI and IISWBM Executive Alumni status
                                    </div>
                                </article>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={6} lg={3} className="mt-4">
                        <Card className="border-0 rounded-0 shadow">
                            <Card.Body className="p-4">
                                <article className="d-flex">
                                    <div className="flex-shrink-0">
                                        <Image
                                            src={PiggyBank}
                                            alt="Icon"
                                            role="presentation"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-3 h6 fw-normal mb-0">
                                        Pay your course fees in easy
                                        installments
                                    </div>
                                </article>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={6} lg={3} className="mt-4">
                        <Card className="border-0 rounded-0 shadow">
                            <Card.Body className="p-4">
                                <article className="d-flex">
                                    <div className="flex-shrink-0">
                                        <Image
                                            src={MoneyHand}
                                            alt="Icon"
                                            role="presentation"
                                            width="42px"
                                            height="42px"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-3 h6 fw-normal mb-0">
                                        Education loan available
                                    </div>
                                </article>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default AdvantageCmi;
