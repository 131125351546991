import { Helmet, HelmetProvider } from 'react-helmet-async';

import Header from '../../components/Header';
import Banner from '../../components/Banner';
import CourseIntro from '../../components/CourseIntro';
import AdvantageCmi from '../../components/AdvantageCmi';
import CourseCurriculum from '../../components/CourseCurriculum';
import CareerPlacement from '../../components/CareerPlacement';
import BrochureSection from '../../components/BrochureSection';
import AdmissionAlumniSection from '../../components/AdmissionAlumni';
import Footer from '../../components/Footer';

import BmBrandLogos from '../../assets/images/BM-Brands-Desktop.png';
import { bmAlumni } from '../../assets/data';
import bmBrochure from '../../assets/brochures/pgdmm-bm-brochure-2022.pdf';

import BmBanner from '../../assets/images/television-production-studio.jpg';

import './index.scss';

const BdcManagement = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Broadcast Media Course, The Best Post Graduation Course
                        in Kolkata
                    </title>
                    <meta
                        name="description"
                        content="Broadcast Media Course, The Best Post Graduation Course in Kolkata from Calcutta Media Institute. Get placed in top companies. 100% placement assistance."
                    />
                    <meta
                        name="keywords"
                        content="media science course in kolkata, media science colleges in kolkata, media management course, broadcast media course, radio broadcasting training, diploma in radio management, diploma in film and television, diploma in film and television production"
                    />
                </Helmet>
                <Header />
                <Banner
                    title={
                        <>
                            Become a <br className="d-block d-sm-none" />
                            Television and Radio Professional in just 1 year
                        </>
                    }
                    subTitle="Post Graduate Diploma in Media Management"
                    image={BmBanner}
                />
                <CourseIntro
                    title="Specialise in Broadcast Management"
                    subTitle="A one-of-a-kind media management course created to give you the opportunity to gain industry exposure and acquire professional skills in order to pursue a successful career in India’s television, radio, and entertainment industries."
                    placementInfo="90% Campus placement record in last 5 years"
                />
                <AdvantageCmi
                    title={
                        <>
                            CMI <span className="text-danger">+</span>{' '}
                            IISWBM&nbsp;
                            <br />
                            double the advantage
                        </>
                    }
                    subTitle={
                        <>
                            Calcutta Media Institute (CMI), promoted by ABP Pvt.
                            Ltd. and the{' '}
                            <strong>
                                Indian Institute of Social Welfare &amp;
                                Business Management
                            </strong>{' '}
                            (IISWBM), a pioneer in business management studies
                            in India, have partnered to offer a one-year{' '}
                            <strong>
                                Post Graduate Diploma in Media Management
                            </strong>{' '}
                            (PGDMM) from <strong>University of Calcutta</strong>{' '}
                            with a specialisation in{' '}
                            <strong>Broadcast Management</strong> (BM).
                        </>
                    }
                />
                <CourseCurriculum
                    title="Skills you will gain"
                    subtitle={
                        <>
                            <p>
                                One full academic year of the broadcast media
                                management course spread over includes theory
                                classes, projects, assignments, and industry
                                internships.
                            </p>
                        </>
                    }
                    columnOne={
                        <>
                            <li>
                                Business Organization and Management Science in
                                Communications
                            </li>
                            <li>
                                Business Economics and Basic Accounting for
                                Managers
                            </li>
                            <li>Marketing Management</li>
                            <li>Brand Management</li>
                            <li>Media and Communication Research</li>
                            <li>Culture and Communication</li>
                            <li>Events Management</li>
                            <li>Business of Media</li>
                            <li>Growth of Television Industry in India</li>
                        </>
                    }
                    columnTwo={
                        <>
                            <li>
                                Television Content Development and Management
                            </li>
                            <li>Television Production Management</li>
                            <li>Television Production Technology</li>
                            <li>
                                Television Channel Management – GEC and News
                            </li>
                            <li>Radio Programming and Management</li>
                            <li>Radio Marketing and Branding</li>
                            <li>Television Planning and Buying</li>
                            <li>Marketing and Managing Television</li>
                            <li>Internship and Project Submission</li>
                        </>
                    }
                />
                <CareerPlacement
                    title={
                        <>
                            Launch your career as a proficient Broadcast Media
                            professional
                        </>
                    }
                    subTitle="Choose from a wide range of career options
                        like television programming and production, radio
                        programming, television and radio marketing & branding,
                        media sales and more. Get to work with the best names in
                        the industry!"
                    featureImage={BmBrandLogos}
                />
                <BrochureSection
                    courseBrochure={bmBrochure}
                    brochureFileName="CMI-PGDMM-BM-Brochure-2022"
                />
                <AdmissionAlumniSection courseAlumni={bmAlumni} />
                <Footer />
            </HelmetProvider>
        </>
    );
};

export default BdcManagement;
