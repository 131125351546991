export const courseUrl = [
    'integrated-marketing-communication-management',
    'thank-you',
    'broadcast-management',
    'digital-marketing-and-social-media-management',
];

export const CmiCommonData = [
    {
        AuthToken: 'CMIINSTITUTE-01-04-2022',
        Source: 'cmiinstitute',
        LeadSource: '11',
        LeadType: '2',
        Course: '1',
        Center: '1',
    },
];
export const Year = () => {
    return new Date().getFullYear();
};

export const SessionDuration = () => {
    const year = new Date().getFullYear();
    const session = year + '-' + (year + 1);
    return session;
};
/**
 * This component returns the page slug
 * it is dependent on useLocation function from react-router-dom
 *
 * import {useLocation} from 'react-router-dom
 * const location = useLocation();
 * GetPageSlug(location)
 * @param {useLocation} location
 */
export const GetPageSlug = (location) => {
    let pageSlug = '';
    const usePathname = location.pathname;
    if (usePathname.charAt(0) === '/') {
        pageSlug = usePathname.slice(1);
        return pageSlug;
    }
    return;
};

/**
 * This component returns error messages 
 * for React hook forms
 *

 * @param {errors} error
 */
export const FormErrorMessage = (error) => {
    if (!error.errors) return null;
    return <span className="small text-danger">{error.errors.message}</span>;
};

// API related helper functions

/**
 * This function returns course id  
 * to be passed onto API from landing page slug
 *

 * @param {slug} page slug or url
 */
export const GetCourseId = (slug, courseUrl) => {
    slug = slug.replace(/\/$/, '');
    if (courseUrl.includes(slug)) {
        let index = courseUrl.indexOf(slug) + 1;
        return index;
    }
};

export const GetUrlParams = (location) => {
    const utmChannel =
        new URLSearchParams(location.search).get('utm_source') || '';
    const utmMedium =
        new URLSearchParams(location.search).get('utm_medium') || '';
    const utmCampaign =
        new URLSearchParams(location.search).get('utm_campaign') || '';

    return {
        leadChannel: utmChannel,
        leadMedium: utmMedium,
        leadCampaign: utmCampaign,
    };
};
