import { Container, Col, Row, Image } from 'react-bootstrap';
import CULogoPng from '../../assets/images/University-of-Calcutta.png';
import IiswbmLogoPng from '../../assets/images/iiswbm-logo-01.png';
import AbpLogoPng from '../../assets/images/abp-pvt-logo.png';
import './index.scss';

const Banner = (props) => {
    return (
        <section
            id="banner"
            style={{
                backgroundImage: `url(${props.image})`,
            }}
        >
            <Container fluid="md">
                <Row>
                    <Col xs={12} sm={9} md={8} lg={7} xl={7} className="py-5">
                        <article className="d-flex flex-column justify-content-center my-5">
                            <h1>{props.title}</h1>
                            <h2 className="body-ff fs-5 fw-normal">
                                {props.subTitle}
                            </h2>
                            <Row className="mt-4 mb-4 g-3 g-lg-2">
                                <Col xs={3} lg={2} className="text-center">
                                    <p className="xs-small mb-2">
                                        In partnership with
                                    </p>
                                    <Image
                                        src={IiswbmLogoPng}
                                        fluid
                                        alt="IISWBM Logo"
                                        width={85}
                                        className="color-filter"
                                    />
                                </Col>
                                <Col xs={3} lg={2} className="text-center">
                                    <p className="xs-small mb-2">
                                        Diploma awarded by
                                    </p>
                                    <Image
                                        src={CULogoPng}
                                        fluid
                                        alt="Calcutta University Logo"
                                        width={85}
                                        className="color-filter"
                                    />
                                </Col>
                                <Col
                                    xs={{ offset: 3, span: 3 }}
                                    lg={{ offset: 2, span: 2 }}
                                    className="text-center d-flex flex-column"
                                >
                                    <p className="xs-small mb-2o">
                                        Promoted by
                                    </p>
                                    <Image
                                        src={AbpLogoPng}
                                        fluid
                                        alt="ABP Group Logo"
                                        width={90}
                                        height="auto"
                                        className="mx-auto"
                                    />
                                </Col>
                            </Row>
                        </article>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Banner;
