import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import ImcManagement from './pages/ImcManagement';
import BdcManagement from './pages/BdcManagement';
import DmsManagement from './pages/DmsManagement';
import ThankYou from './pages/ThankYou';
import NoMatch from './pages/NoMatch';

import './App.scss';
const App = () => {
    return (
        <>
            <Switch>
                <Route exact path="/">
                    {/* <Redirect to="/integrated-marketing-communication-management" /> */}
                    <Home />
                </Route>
                <Route path="/integrated-marketing-communication-management">
                    <ImcManagement />
                </Route>
                <Route path="/broadcast-management">
                    <BdcManagement />
                </Route>
                <Route path="/digital-marketing-and-social-media-management">
                    <DmsManagement />
                </Route>
                <Route path="/thank-you">
                    <ThankYou />
                </Route>
                <Route path="*">
                    <NoMatch />
                </Route>
            </Switch>
            <ToastContainer autoClose={5000} />
        </>
    );
};

export default App;
