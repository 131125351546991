import { Container, Row, Col } from 'react-bootstrap';
import BackgroundImage from '../../assets/images/Calcutta-University.jpeg';
import FeatureImage from '../../assets/images/CU-ashutosh-building.jpg';
import './index.scss';
const CourseCurriculum = (props) => {
    return (
        <section
            id="curriculum-section"
            className="my-0 my-md-5"
            style={{
                backgroundImage: `url(${BackgroundImage})`,
            }}
        >
            <Container fluid>
                <Row className="justify-content-md-end">
                    <Col xs={12} className="d-flex d-lg-none">
                        <Row>
                            <img
                                className="img-fluid px-0"
                                src={FeatureImage}
                                alt="Calcutta University"
                                loading="lazy"
                            />
                        </Row>
                    </Col>
                    <Col
                        xs={12}
                        lg={9}
                        xl={7}
                        className="text-white red-gradient "
                    >
                        <article className="py-5 px-3 px-md-5">
                            <Row>
                                <Col lg={12} xl={9}>
                                    <h2>{props.title}</h2>
                                    <div>{props.subtitle}</div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col sm={6}>
                                    <ul className="list-unstyled mb-0 mb-md-3">
                                        {props.columnOne}
                                    </ul>
                                </Col>
                                <Col sm={6}>
                                    <ul className="list-unstyled mb-0 mb-md-3">
                                        {props.columnTwo}
                                    </ul>
                                </Col>
                            </Row>
                        </article>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default CourseCurriculum;
